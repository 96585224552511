import { render, staticRenderFns } from "./index.vue?vue&type=template&id=377b98db&scoped=true&functional=true&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=377b98db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "377b98db",
  null
  
)

export default component.exports